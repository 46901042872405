/* eslint-disable import/no-extraneous-dependencies */
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_BOOTSTRAP_LISTENER, ComponentRef, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpInterceptorProviders } from 'src/app/shared/interceptors/http-interceptor';
import { translateBrowserLoaderFactory } from 'src/app/shared/loader/translate-browser.loader.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material/material.module';
import { GoogleAnalyticService } from './core/services/analytics.service';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        AppComponent,
        SignUpComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'GoldenScreenCinemas' }),
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState],
            },
        }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        FormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MaterialModule,
        NgxGoogleAnalyticsModule,
        DigitOnlyModule,
        LottieModule.forRoot({ player: playerFactory })
    ],
    providers: [
        httpInterceptorProviders,
        { provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
            useValue: {
                trackingCode: environment.gaMid,
                initCommands: [{ command: 'config', values: [{ send_page_view: false, client_storage: 'none', anonymize_ip: true }] }],
                nonce: 'FBCc7XlLkS0UPwyoTGzYag==',
            },
        },
        {
            provide: APP_BOOTSTRAP_LISTENER,
            multi: true,
            useFactory: (analyticsService: GoogleAnalyticService) => (component: ComponentRef<unknown>) => {
                analyticsService.initializeGoogleAnalytics(component);
            },
            deps: [GoogleAnalyticService],
        }],
    bootstrap: [AppComponent],
})
export class AppModule { }
