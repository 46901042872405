export const CONFIRM_TICKET_TYPE_URL = 'confirm-ticket-type';
export const CONFIRM_TICKET_TYPE_HALL_BOOKING_URL = 'hall-booking/confirm-ticket-type';
export const CINEMA_LISTING_URL = 'cinemas';
export const HALL_BOOKING_LISTING_URL = 'hall-booking';
export const HALL_BOOKING_URL = 'hall-booking/showtime-by-cinemas';
export const ECOMBO_URL = 'e-combo';
export const ECOMBO_HALL_BOOKING_URL = 'hall-booking/e-combo';
export const AURUM_ECOMBO_URL = 'aurum-e-combo';
export const AURUM_ECOMBO_HALL_BOOKING_URL = 'hall-booking/aurum-e-combo';
export const FAST_TICKET_URL = 'fast-ticket';
export const FNB_URL = 'fnb';
export const FNB_COMBO_URL = 'fnb/e-combo';
export const FNB_ORDER_HISTORY_URL = 'fnb/order-history';
export const REVIEW_SUMMARY_URL = 'review-summary';
export const REVIEW_SUMMARY_FNB_URL = 'fnb/review-summary';
export const REVIEW_SUMMARY_HALL_BOOKING_URL = 'hall-booking/review-summary';
export const SEAT_SELECTION_URL = 'seat-selection';
export const SEAT_SELECTION_HALL_BOOKING_URL = 'hall-booking/seat-selection';
export const SHOWTIME_BY_CINEMAS_URL = 'cinemas/showtime-by-cinemas';
export const SHOWTIME_BY_MOVIES_URL = 'showtime-by-movies';
export const SIGN_UP_OTP = 'sign-up/otp';
export const FORGET_PASSWORD_OTP = 'forgot-password/otp';
export const REWARDS_CENTER = 'rewards-center';
export const TERMS_AND_CONDITION = 'https://www.gsc.com.my/terms-and-conditions/';
export const PRIVACY_POLICY = 'https://www.gsc.com.my/privacy-policy/';
export const FIRST_TIME_LOGIN_OTP = 'first-time-login/otp';
export const AURUM_E_COMBO = 'aurum-e-combo';
export const UPSELLING_URL = 'upselling';
export const FNB_UPSELLING_URL = `${FNB_URL}/${UPSELLING_URL}`;
export const E_INVOICE_URL = 'einvoice';

// Auth routes
export const FORGOT_PASSWORD_URL = 'forgot-password';
export const SIGN_UP_URL = 'sign-up';
export const LOGIN_URL = 'login';
export const SETUP_PASSWORD_URL = 'forgot-password/setup-password';
export const FIRST_TIME_LOGIN_URL = 'first-time-login/setup-password';

// Profile routes
export const PROFILE_URL = 'profile';
export const MY_TICKET_URL = 'my-ticket';
export const MY_TICKET_INVOICE_URL = 'my-ticket-invoice';
export const MY_REWARDS_URL = 'my-rewards';
export const MY_PAST_REWARDS_URL = 'my-past-rewards';
export const MY_REWARDS_DETAILS_URL = 'my-rewards-details';
export const MY_PAST_REWARDS_DETAILS_URL = 'my-rewards-past-details';
export const REWARDS_CENTRE_URL = 'rewards-centre';
export const REFERRALS_URL = 'referrals';
export const FAVOURITE_URL = 'favourite';
export const MESSAGE_URL = 'message';
export const LANGUAGE_URL = 'language';
export const CHANGE_PASSWORD_URL = 'change-password';
export const DELETE_ACCOUNT_URL = 'delete-account';
export const MY_GSCOINS_URL = 'my-gscoins';
export const EDIT_PROFILE_URL = 'edit-profile';
export const REWARDS_CENTRE_DETAILS_URL = 'rewards-centre-details';

export const PurchaseFlowRoutes = [
    `/${LOGIN_URL}`,
    `/${SEAT_SELECTION_URL}`,
    `/${SEAT_SELECTION_HALL_BOOKING_URL}`,
    `/${CONFIRM_TICKET_TYPE_URL}`,
    `/${CONFIRM_TICKET_TYPE_HALL_BOOKING_URL}`,
    `/${AURUM_ECOMBO_URL}`,
    `/${AURUM_ECOMBO_HALL_BOOKING_URL}`,
    `/${ECOMBO_URL}`,
    `/${ECOMBO_HALL_BOOKING_URL}`,
    `/${FNB_URL}`,
    `/${FNB_COMBO_URL}`,
    `/${UPSELLING_URL}`,
    `/${FNB_UPSELLING_URL}`,
    `/${REVIEW_SUMMARY_URL}`,
    `/${REVIEW_SUMMARY_FNB_URL}`,
    `/${REVIEW_SUMMARY_HALL_BOOKING_URL}`,
    `/${PROFILE_URL}/${MY_TICKET_URL}`,
];

export const ROUTES_TO_SKIP = [
    `/${FORGET_PASSWORD_OTP}`,
    `/${FIRST_TIME_LOGIN_OTP}`,
    `/${SIGN_UP_OTP}`,
    `/${SETUP_PASSWORD_URL}`,
    `/${FIRST_TIME_LOGIN_URL}`,
];

export const COUNTRY_CODE = [
    {
        id: 0,
        flag: '../../../assets/img/icons/malaysia_flag_icon@2x.png',
        country: 'MY',
        code: '+6',
    },
    {
        id: 1,
        flag: '../../../assets/img/icons/singapore_flag_icon@2x.png',
        country: 'SG',
        code: '+65',
    },
    {
        id: 2,
        flag: '../../../assets/img/icons/brunei_flag_icon@2x.png',
        country: 'BN',
        code: '+673',
    },
];

export const GENDER = [
    {
        id: 'Male',
        type: 'Male (M)',
        mapValue: 'M',
    },
    {
        id: 'Female',
        type: 'Female (F)',
        mapValue: 'F',
    },

];

export const PROFESSION = [
    {
        id: -1,
        type: 'Select profession',
    },
    {
        id: 0,
        type: 'Student',
    },
    {
        id: 1,
        type: 'Corporate',
    },
    {
        id: 2,
        type: 'Business Owner',
    },
    {
        id: 3,
        type: 'Unemployed',
    },
    {
        id: 'Others',
        type: 'Others',
    },
];

export const LOCATION = [
    { id: -1, name: 'Select state' },
    { id: 0, name: 'Selangor' },
    { id: 1, name: 'Kuala Lumpur' },
    { id: 2, name: 'Putrajaya' },
    { id: 3, name: 'Penang' },
    { id: 4, name: 'Kedah' },
    { id: 5, name: 'Johor' },
    { id: 6, name: 'Kelantan' },
    { id: 7, name: 'Melaka' },
    { id: 8, name: 'Negeri Sembilan' },
    { id: 9, name: 'Pahang' },
    { id: 10, name: 'Perak' },
    { id: 11, name: 'Perlis' },
    { id: 12, name: 'Sabah' },
    { id: 13, name: 'Sarawak' },
    { id: 14, name: 'Labuan' },
    { id: 15, name: 'Terengganu' },
];

export const RACE = [
    {
        id: -1,
        name: 'Select race',
    },
    {
        id: 0,
        name: 'Malay',
    },
    {
        id: 1,
        name: 'Chinese',
    },
    {
        id: 2,
        name: 'Indian',
    },
    {
        id: 3,
        name: 'Others',
    },
];

// TODO: to map this if the api spec if ready
export const IDENTIFICATION_TYPE = [
    {
        id: 'NRIC',
        name: 'NRIC',
    },
    {
        id: 'Passport number',
        name: 'Passport No(Non Malaysian)',
    },
    {
        id: 'BRN',
        name: 'Business Registration No.',
    },
];

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

export const profileMenuItems = [
    {
        module: 'For more value',
        menu: [
            { name: 'My Tickets', url: MY_TICKET_URL },
            { name: 'My Rewards', url: MY_REWARDS_URL },
            { name: 'Rewards Centre', url: REWARDS_CENTRE_URL },
            // { name: 'Referrals', url: REFERRALS_URL },
        ],
    },
    {
        module: 'My Account',
        menu: [
            { name: 'Favourite', url: FAVOURITE_URL },
            // { name: 'Message', url: MESSAGE_URL },
            // { name: 'Language', url: LANGUAGE_URL },
        ],
    },
    {
        module: 'Settings',
        menu: [
            { name: 'Change Password', url: CHANGE_PASSWORD_URL },
            { name: 'Delete Account', url: DELETE_ACCOUNT_URL },
        ],
    },
];

export const DYNAMIC_NAMES = {
    COUNTRY_CODE: 'CountryCode',
    GENDER: 'Gender',
    RACE_CODE: 'RaceCode',
    OCCUPATION_CODE: 'OccupationCode',
    STATE: 'State',
    RECEIVE_NOTIFICATION: 'ReceiveNotification',
    FAVOURITE_CINEMA: 'FavouriteCinema',
    MIGATED_MEMBER: 'MigratedMember',
    NOTIFY_SMS: 'NotifySMS',
    NOTIFY_POST: 'NotifyPost',
};

// TODO: TBC
export const SMART_APP_BANNER_TITLE = 'Golden Screen Cinemas';
export const SMART_APP_BANNER_AUTHOR = 'Golden Screen Cinemas Sdn Bhd';
export const IOS_APP_STORE_URL = 'https://apps.apple.com/my/app/golden-screen-cinemas/id413024972';
export const ANDROID_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.gscandroid.yk&hl=en&gl=US&pli=1';

export const EMPTY_TNC = '<html>\r\n<head>\r\n\t<title></title>\r\n</head>\r\n<body></body>\r\n</html>\r\n';

export const DBOX_TITLE = 'D-Box Safety Guidelines';
export const FOURDX_TITLE = '4DX Safety Guidelines';
export const PP_TITLE = 'PP Safety Guidelines';

export const INPUT_KEYS = [
    'ENTER',
    'CAPS',
    'SHIFT',
    'TAB',
    'ALT',
    'CONTROL',
];

export const E_INVOICE_DATA = {
    postcode: '',
    city: '',
    country: '',
    state: '',
    eInvoiceState: '',
};

export const ADS_SCRIPT = `
    window.googletag = window.googletag || {cmd: []};

    googletag.cmd.push(function() {

    googletag.defineSlot('/28335045/SelfPrintTicket_AdPromo_1', [320, 50], 
    'div-gpt-ad-1722389780635-0').addService(googletag.pubads());

    googletag.pubads().enableSingleRequest();

    googletag.enableServices();

    });

    googletag.cmd.push(
        () => { googletag.display('div-gpt-ad-1722389780635-0'); },
    )
`;

// GOOGLE ANALYTICS DATA LAYER
export const DATA_LAYER_EVENT = 'purchase';
export const SHIPPING = 0.00;
export const CURRENCY = 'MYR';
export const TICKET_TYPE = 'Ticket Type';
export const ADD_ON = 'Add-On';
export const UPSELL = 'Upsell';

// LEAVE PAGE POPUP MODEL
export const CANCEL_BOOKING_MODEL = {
    title: 'Cancel Booking',
    description: 'Are you sure you want to cancel this booking?',
};

// FNB Landing
export const COLLECTED_BY = ['Today', 'Schedule'];
