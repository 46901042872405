/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LOCK_SEAT_DURATION_IN_SECONDS } from 'src/app/core/constants/review-summary';
import { AscentisMemberAuthentication } from 'src/app/core/models/ascentis-login/member-authentication';
import { BookingsHistory } from 'src/app/core/models/fast-ticket/bookings-history';
import { GetTicketListingModel } from 'src/app/core/models/fast-ticket/ticket-listing';
import { UserFavCinema } from 'src/app/core/models/favourite-cinema/favourite-cinema';
import { Films } from 'src/app/core/models/movie-listing/films';
import { AscentisQRToken } from 'src/app/core/models/profile/token';
import { Locations } from 'src/app/core/models/showtimes-by-movies/locations';
import { SelectedVariationModel } from 'src/app/core/models/ticket-pricing/variation.model';
import { ConfirmTicketTypeCache } from '../models/confirm-ticket-type/confirm-ticket-type-cache.model';
import { InitSalesResponseModel } from '../models/payment/initsales_response_body/initsales_response_model';
import { InitSalesTransaction } from '../models/payment/initsales_transaction/initsales_transaction';
import { MemberEnquiry } from '../models/profile/member-enquiry';
import { RegisterUserInfoBody } from '../models/register/register-user-info-body';
import { SeatSelectionCacheMain } from '../models/seat-selection/seat-selection-cache-main.model';
import { SeatSelectionModel } from '../models/seat-selection/seat-selection.model';
import { ShowtimeBannerDetails } from '../models/seat-selection/showtime-banner-details.model';
import { UpdateProfileModel } from '../models/update-profile/update-profile.model';
import { MyVoucherList } from '../models/voucher/my-voucher-list';
import { VoucherTypeList } from '../models/voucher/voucher-type-list';
import { UpsellingType } from '../enums/movie-type.enum';
import { RecommendDetails } from '../models/fast-ticket/recommended-showtime.model';
import { FnbData } from '../models/fnb/fnb-data';
import { FnbUpsell } from '../models/fnb/fnb-product/fnb-upsell';
import { FnbSalesTransaction } from '../models/payment/fnb/fnb-initsales/fnb-transaction';
import { FnbInitSalesResponseBody } from '../models/payment/fnb/fnb-initsales-response/fnb-initsales-response-body';
import { FnbInitSalesResponseWrapper } from '../models/payment/fnb/fnb-initsales-response/fnb-initsales-response-wrapper';
import { UpsellItem } from '../models/upsell/upsell-item';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    get userName(): string {
        return localStorage.getItem('userName') || '';
    }

    set userName(userName: string) {
        localStorage.setItem('userName', userName || '');
    }

    get memberCardNo(): string {
        return localStorage.getItem('memberCardNo') || '';
    }

    set memberCardNo(memberCardNo: string) {
        localStorage.setItem('memberCardNo', memberCardNo || '');
    }

    get ticketData(): any {
        return sessionStorage.getItem('ticketData');
    }

    set ticketData(data: any) {
        sessionStorage.setItem('ticketData', data);
    }

    get mappingData(): any {
        return sessionStorage.getItem('mappingData');
    }

    set mappingData(data: any) {
        sessionStorage.setItem('mappingData', data);
    }

    set selectedShowtimesDetails(data: Locations | null) {
        sessionStorage.setItem('selectedShowtime', JSON.stringify(data));
    }

    get selectedShowtimesDetails(): Locations | null {
        return sessionStorage.getItem('selectedShowtime') ? JSON.parse(sessionStorage.getItem('selectedShowtime') || '') as Locations : null;
    }

    set showtimesApiModel(data: SeatSelectionModel | null) {
        sessionStorage.setItem('seatsShowtimeApi', JSON.stringify(data));
    }

    get showtimesApiModel(): SeatSelectionModel | null {
        return sessionStorage.getItem('seatsShowtimeApi') ? JSON.parse(sessionStorage.getItem('seatsShowtimeApi') || '') as SeatSelectionModel : null;
    }

    set showtimesBannerDetails(data: ShowtimeBannerDetails | null) {
        sessionStorage.setItem('bannerDetails', JSON.stringify(data));
    }

    get showtimesBannerDetails(): ShowtimeBannerDetails | null {
        return sessionStorage.getItem('bannerDetails') ? JSON.parse(sessionStorage.getItem('bannerDetails') || '') as ShowtimeBannerDetails : null;
    }

    set selectedEcomboDetails(data: SelectedVariationModel[]) {
        sessionStorage.setItem('eComboDetails', JSON.stringify(data));
    }

    get selectedEcomboDetails(): SelectedVariationModel[] {
        const data = sessionStorage.getItem('eComboDetails');
        if (data) { return JSON.parse(data) as SelectedVariationModel[]; }
        return [];
    }

    set selectedAddOnDetails(itm: SelectedVariationModel[]) {
        sessionStorage.setItem('addOnDetails', JSON.stringify(itm) ?? []);
    }

    get selectedAddOnDetails(): SelectedVariationModel[] {
        const data = sessionStorage.getItem('addOnDetails');
        if (data) { return JSON.parse(data) as SelectedVariationModel[]; }
        return [];
    }

    set selectedAurumBundleDetails(data: SelectedVariationModel[]) {
        sessionStorage.setItem('aurumBundleDetails', JSON.stringify(data));
    }

    get selectedAurumBundleDetails(): SelectedVariationModel[] {
        const data = sessionStorage.getItem('aurumBundleDetails');
        if (data) { return JSON.parse(data) as SelectedVariationModel[]; }
        return [];
    }

    set lockSeatBody(data: InitSalesTransaction | null) {
        sessionStorage.setItem('lockSeatBody', JSON.stringify(data));
    }

    get lockSeatBody(): InitSalesTransaction | null {
        return sessionStorage.getItem('lockSeatBody') ? JSON.parse(sessionStorage.getItem('lockSeatBody') || '') as InitSalesTransaction : null;
    }

    set upsellLockSeatBody(data: FnbSalesTransaction | null) {
        sessionStorage.setItem('upsellLockSeatBody', JSON.stringify(data) ?? '');
    }

    get upsellLockSeatBody(): FnbSalesTransaction | null {
        return sessionStorage.getItem('upsellLockSeatBody') ? JSON.parse(sessionStorage.getItem('upsellLockSeatBody') || '') as FnbSalesTransaction : null;
    }

    set bookingID(data: string | null) {
        sessionStorage.setItem('bookingID', JSON.stringify(data));
    }

    get bookingID(): string | null {
        return sessionStorage.getItem('bookingID') ? JSON.parse(sessionStorage.getItem('bookingID') || '') : null;
    }

    set initSalesResponse(data: InitSalesResponseModel | null) {
        sessionStorage.setItem('initResponse', JSON.stringify(data));
    }

    get initSalesResponse(): InitSalesResponseModel | null {
        return sessionStorage.getItem('initResponse') ? JSON.parse(sessionStorage.getItem('initResponse') || '') as InitSalesResponseModel : null;
    }

    set fnbInitSalesResponse(body: FnbInitSalesResponseWrapper | null) {
        sessionStorage.setItem('fnbInitSalesRes', JSON.stringify(body) ?? '');
    }

    get fnbInitSalesResponse(): FnbInitSalesResponseWrapper | null {
        return sessionStorage.getItem('fnbInitSalesRes') ? JSON.parse(sessionStorage.getItem('fnbInitSalesRes') ?? '') : null;
    }

    set fastTicketUserHistory(data: BookingsHistory | null) {
        localStorage.setItem('fastTicketUserHistory', JSON.stringify(data));
    }

    get fastTicketUserHistory(): BookingsHistory | null {
        return localStorage.getItem('fastTicketUserHistory')
            ? JSON.parse(localStorage.getItem('fastTicketUserHistory') || '') as BookingsHistory
            : null;
    }

    set fastTicketMovieListing(data: Films | null) {
        localStorage.setItem('fastTicketMovieListing', JSON.stringify(data));
    }

    get fastTicketMovieListing(): Films | null {
        return localStorage.getItem('fastTicketMovieListing')
            ? JSON.parse(localStorage.getItem('fastTicketMovieListing') || '') as Films
            : null;
    }

    set fastTicketShowtimes(data: Locations | null) {
        localStorage.setItem('fastTicketShowtimes', JSON.stringify(data));
    }

    get fastTicketShowtimes(): Locations | null {
        return localStorage.getItem('fastTicketShowtimes')
            ? JSON.parse(localStorage.getItem('fastTicketShowtimes') || '') as Locations
            : null;
    }

    set userMembershipInfo(data: GetTicketListingModel | null) {
        localStorage.setItem('userMembership', JSON.stringify(data));
    }

    get userMembershipInfo(): GetTicketListingModel | null {
        return localStorage.getItem('userMembership')
            ? JSON.parse(localStorage.getItem('userMembership') || '') as GetTicketListingModel
            : null;
    }

    set userFavouriteCinema(data: UserFavCinema[] | null) {
        sessionStorage.setItem('userFavouriteCinema', JSON.stringify(data));
    }

    get userFavouriteCinema(): UserFavCinema[] | null {
        return sessionStorage.getItem('userFavouriteCinema')
            ? JSON.parse(sessionStorage.getItem('userFavouriteCinema') || '')
            : null;
    }

    set fastTicketReloadEventFlag(isRefresh: boolean) {
        sessionStorage.setItem('fastTicketReloadEventFlag', JSON.stringify(isRefresh));
    }

    get fastTicketReloadEventFlag(): boolean {
        return sessionStorage.getItem('fastTicketReloadEventFlag')
            ? JSON.parse(sessionStorage.getItem('fastTicketReloadEventFlag') || '') as boolean
            : false;
    }

    set registrationInfo(data: RegisterUserInfoBody | null) {
        localStorage.setItem('registrationInfo', JSON.stringify(data));
    }

    get registrationInfo(): RegisterUserInfoBody | null {
        return localStorage.getItem('registrationInfo')
            ? JSON.parse(localStorage.getItem('registrationInfo') || '') as RegisterUserInfoBody
            : null;
    }

    set countryCode(code: string) {
        sessionStorage.setItem('countryCode', code);
    }

    get countryCode(): string {
        return sessionStorage.getItem('countryCode') ?? '';
    }

    set mobileNo(no: string) {
        sessionStorage.setItem('mobileNo', no);
    }

    get mobileNo(): string {
        return sessionStorage.getItem('mobileNo') ?? '';
    }

    set accessToken(data: string) {
        localStorage.setItem('accessToken', data);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    set profileQRToken(data: AscentisQRToken | null) {
        localStorage.setItem('profileQrToken', JSON.stringify(data));
    }

    get profileQRToken(): AscentisQRToken | null {
        return localStorage.getItem('profileQrToken')
            ? JSON.parse(localStorage.getItem('profileQrToken') || '') as AscentisQRToken
            : null;
    }

    set reviewSummaryCountdown(time: number) {
        sessionStorage.setItem('reviewSummaryCountdown', JSON.stringify(time));
    }

    get reviewSummaryCountdown(): number {
        return sessionStorage.getItem('reviewSummaryCountdown')
            ? JSON.parse(sessionStorage.getItem('reviewSummaryCountdown') || '') as number
            : LOCK_SEAT_DURATION_IN_SECONDS;
    }

    clearFastTicket(): void {
        this.userFavouriteCinema = null;
        this.fastTicketMovieListing = null;
        this.fastTicketShowtimes = null;
        this.fastTicketUserHistory = null;
        this.selectedShowtimesDetails = null;
        this.mappingData = null;
        this.ticketData = null;
        this.showtimesApiModel = null;
        this.showtimesBannerDetails = null;
        this.confirmTicketTypeData = null;
        this.seatSelectionData = null;
        this.recommendedShowtimes = null;
        this.initSalesResponse = null;
        this.selectedEcomboDetails = [];
        this.selectedAurumBundleDetails = [];
        this.selectedAddOnDetails = [];
        this.reviewSummaryEntry = null;
        this.reviewSummaryCountdown = 0;
        this.reviewSummaryTimerDiff = null;
        this.clearFnbData();
        this.upsellProduct = null;
    }

    clearUserShowtimesSelection(): void {
        this.selectedShowtimesDetails = null;
    }

    set currentLanguage(language: string | null) {
        localStorage.setItem('currentLanguage', language ?? 'EN');
    }

    get currentLanguage(): string | null {
        return localStorage.getItem('currentLanguage') ?? 'EN';
    }

    setFromLogin(input: AscentisMemberAuthentication): void {
        if (input.MemberLists) {
            this.userName = input.MemberLists[0].Name;
            this.userMembershipInfo = {
                curdt: moment().format(),
                mbrid: input.MemberLists[0].MemberID,
                emailid: input.MemberLists[0].Email,
                phoneno: input.MemberLists[0].MobileNo,
                token: '1',
            };
        }

        if (input.CardLists && input.CardLists.length) {
            this.memberCardNo = input.CardLists[0].CardNo;
        }
    }

    firstTimeLogin(data: UpdateProfileModel | MemberEnquiry) {
        this.userName = data.MemberLists[0].Name;

        this.userMembershipInfo = {
            curdt: moment().format(),
            mbrid: data.MemberLists[0].MemberID,
            emailid: data.MemberLists[0].Email,
            phoneno: data.MemberLists[0].MobileNo,
            token: '1',
        };

        if (data.CardLists && data.CardLists.length) {
            this.memberCardNo = data.CardLists[0].CardNo;
        }
    }

    set memberInfo(data: GetTicketListingModel | null) {
        localStorage.setItem('memberInfo', JSON.stringify(data));
    }

    get memberInfo(): GetTicketListingModel | null {
        return localStorage.getItem('memberInfo')
            ? JSON.parse(localStorage.getItem('memberInfo') || '') as GetTicketListingModel
            : null;
    }

    set rewardsCenterListing(data: VoucherTypeList[] | []) {
        localStorage.setItem('rewardCenterList', JSON.stringify(data));
    }

    get rewardsCenterListing(): VoucherTypeList[] | [] {
        return localStorage.getItem('rewardCenterList')
            ? JSON.parse(localStorage.getItem('rewardCenterList') || '') as VoucherTypeList[]
            : [];
    }

    set seatSelectionData(data: SeatSelectionCacheMain | null) {
        localStorage.setItem('seatSelectionData', JSON.stringify(data));
    }

    get seatSelectionData(): SeatSelectionCacheMain | null {
        return localStorage.getItem('seatSelectionData')
            ? JSON.parse(localStorage.getItem('seatSelectionData') || '') as SeatSelectionCacheMain
            : null;
    }

    set confirmTicketTypeData(data: ConfirmTicketTypeCache | null) {
        localStorage.setItem('confirmTicketType', JSON.stringify(data));
    }

    get confirmTicketTypeData(): ConfirmTicketTypeCache | null {
        return localStorage.getItem('confirmTicketType')
            ? JSON.parse(localStorage.getItem('confirmTicketType') || '') as ConfirmTicketTypeCache
            : null;
    }

    set upsellProduct(data: any[] | null) {
        sessionStorage.setItem('upsellProduct', JSON.stringify(data));
    }

    get upsellProduct(): any[] | null {
        return sessionStorage.getItem('upsellProduct')
            ? JSON.parse(sessionStorage.getItem('upsellProduct') || '') as any[]
            : null;
    }

    set myRewardsDetails(data: MyVoucherList | null) {
        localStorage.setItem('myRewardsDetails', JSON.stringify(data));
    }

    get myRewardsDetails(): MyVoucherList | null {
        return localStorage.getItem('myRewardsDetails')
            ? JSON.parse(localStorage.getItem('myRewardsDetails') || '') as MyVoucherList
            : null;
    }

    set rewardDetails(data: VoucherTypeList | null) {
        localStorage.setItem('rewardDetails', JSON.stringify(data));
    }

    get rewardDetails(): VoucherTypeList | null {
        return localStorage.getItem('rewardDetails')
            ? JSON.parse(localStorage.getItem('rewardDetails') || '') as VoucherTypeList
            : null;
    }

    set otpToken(data: string | null) {
        localStorage.setItem('otpToken', data ?? '');
    }

    get otpToken(): string | null {
        return localStorage.getItem('otpToken') ?? '';
    }

    set otpCode(data: string | null) {
        localStorage.setItem('otpCode', data ?? '');
    }

    get otpCode(): string | null {
        return localStorage.getItem('otpCode') ?? '';
    }

    set countdownTime(data: number | null) {
        sessionStorage.setItem('countdownTime', data?.toString() ?? '');
    }

    get countdownTime(): number | null {
        return Number(sessionStorage.getItem('countdownTime')) ?? '';
    }

    set dismissCount(data: number | null) {
        sessionStorage.setItem('dismissCount', data?.toString() ?? '');
    }

    get dismissCount(): number | null {
        return Number(sessionStorage.getItem('dismissCount')) ?? '';
    }

    set upsellingEntryPoint(point: UpsellingType) {
        sessionStorage.setItem('hasUpselling', point.toString() ?? UpsellingType.NoUpselling);
    }

    get upsellingEntryPoint() {
        return Number(sessionStorage.getItem('hasUpselling')) ?? UpsellingType.NoUpselling;
    }

    set ecomboSelection(itm: SelectedVariationModel[]) {
        sessionStorage.setItem('ecomboSelection', JSON.stringify(itm) ?? []);
    }

    get ecomboSelection(): SelectedVariationModel[] {
        const data = sessionStorage.getItem('ecomboSelection');
        if (data) { return JSON.parse(data) as SelectedVariationModel[]; }
        return [];
    }

    clearUserInfo(): void {
        this.userName = '';
        this.accessToken = '';
        this.userMembershipInfo = null;
        this.profileQRToken = null;
        this.memberCardNo = '';
        this.clearFastTicket();
    }

    clearRegistrationInfo() {
        this.registrationInfo = null;
        this.countryCode = '';
        this.mobileNo = '';
        this.otpCode = '';
        this.otpToken = '';
    }

    clearSeatSelectionData() {
        this.seatSelectionData = null;
        this.selectedAddOnDetails = [];
        this.selectedAurumBundleDetails = [];
        this.selectedEcomboDetails = [];
    }

    clearConfirmTicketTypeInfo() {
        this.selectedAddOnDetails = [];
        this.selectedAurumBundleDetails = [];
        this.selectedEcomboDetails = [];
    }

    clearCacheRewardsData() {
        this.myRewardsDetails = null;
        this.rewardDetails = null;
    }

    clearCacheSelectedMovieTicket() {
        this.clearConfirmTicketTypeInfo();
        this.clearUserShowtimesSelection();
        this.clearSeatSelectionData();
        this.clearFnbData();
        this.selectedEcomboDetails = [];
        this.selectedAurumBundleDetails = [];
        this.lockSeatBody = null;
        this.bookingID = null;
        this.initSalesResponse = null;
        this.showtimesBannerDetails = null;
        this.upsellingEntryPoint = UpsellingType.NoUpselling;
        this.ecomboSelection = [];
        this.recommendedShowtimes = null;
        this.selectedAddOnDetails = [];
        this.reviewSummaryEntry = null;
        this.reviewSummaryCountdown = 0;
        this.reviewSummaryTimerDiff = null;
        this.showtimesApiModel = null;
        this.upsellProduct = null;
        this.confirmTicketTypeData = null;
    }

    get chatName(): string {
        return localStorage.getItem('chatName') || '';
    }

    set chatName(name: string) {
        localStorage.setItem('chatName', name || '');
    }

    get chatTime(): string {
        return localStorage.getItem('chatTime') || '';
    }

    set chatTime(time: string) {
        localStorage.setItem('chatTime', time || '');
    }

    set coinsExpiryPopup(acc: any) {
        localStorage.setItem('coinsExpiry', JSON.stringify(acc));
    }

    get coinsExpiryPopup(): any | null {
        return localStorage.getItem('coinsExpiry')
                ? JSON.parse(localStorage.getItem('coinsExpiry') || '')
                : null;
    }

    get recommendedShowtimes(): RecommendDetails | null {
        return localStorage.getItem('recommendedShowtimes')
                ? JSON.parse(localStorage.getItem('recommendedShowtimes') || '')
                : null;
    }

    set recommendedShowtimes(data: RecommendDetails | null) {
        localStorage.setItem('recommendedShowtimes', JSON.stringify(data));
    }

    set reviewSummaryEntry(url: string | null) {
        localStorage.setItem('reviewSummaryEntry', JSON.stringify(url) ?? null);
    }

    get reviewSummaryEntry(): string | null {
        return localStorage.getItem('reviewSummaryEntry') ? JSON.parse(localStorage.getItem('reviewSummaryEntry') || '') : null;
    }

    set reviewSummaryTimerDiff(timer: string | null) {
        localStorage.setItem('reviewSummaryTimerDiff', JSON.stringify(timer) ?? null);
    }

    get reviewSummaryTimerDiff(): string | null {
        return localStorage.getItem('reviewSummaryTimerDiff') ? JSON.parse(localStorage.getItem('reviewSummaryTimerDiff') || '') : null;
    }

    clearCoinsExpiryPopup() {
        this.coinsExpiryPopup = null;
    }

    set fnbData(data: FnbData | null) {
        sessionStorage.setItem('fnbData', JSON.stringify(data) ?? null);
    }

    get fnbData(): FnbData | null {
        return sessionStorage.getItem('fnbData') ? JSON.parse(sessionStorage.getItem('fnbData') || '') : null;
    }

    set fnbUpsell(data: FnbUpsell[] | null) {
        sessionStorage.setItem('fnbUpsell', JSON.stringify(data) ?? null);
    }

    get fnbUpsell(): FnbUpsell[] | null {
        return sessionStorage.getItem('fnbUpsell') ? JSON.parse(sessionStorage.getItem('fnbUpsell') ?? '') : null;
    }

    clearFnbData() {
        this.fnbData = null;
        this.fnbUpsell = null;
        this.selectedAddOnDetails = [];
        this.selectedEcomboDetails = [];
        this.ecomboSelection = [];
    }
}
