/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PurchaseEntryPoint } from 'src/app/core/enums/app.enum';
import { MovieTicketType, UpsellingType } from 'src/app/core/enums/movie-type.enum';
import { AscentisQRToken } from 'src/app/core/models/profile/token';
import { SHOWTIME_DATE_FORMAT, SHOWTIME_TIME_FORMAT_24_HOURS } from '../constants/fast-ticket.constants';
import { sha256 } from 'js-sha256';
import { SALT } from '../constants/api.constants';

@Injectable({
    providedIn: 'root',
})

export class DataService {
    private ticketSource = new BehaviorSubject(undefined);
    selectedTicket = this.ticketSource.asObservable();

    private ticketData = new BehaviorSubject(undefined);
    selectedTicketInfo = this.ticketData.asObservable();

    private mappingSource = new BehaviorSubject(undefined);
    mappingInfo = this.mappingSource.asObservable();

    private refreshProfileFlag = new Subject();

    private refreshMyRewardsCountFlag = new Subject();

    private profileQR = new Subject<AscentisQRToken>();

    private ticketType = new BehaviorSubject<MovieTicketType>(MovieTicketType.TicketWithoutEcombo);

    private upsellingType = new BehaviorSubject(UpsellingType.NoUpselling);

    private myRewardsSource = new BehaviorSubject(true);
    myRewardsType = this.myRewardsSource.asObservable();

    private getRouteSource = new BehaviorSubject('');
    getRoute = this.getRouteSource.asObservable();

    private purchaseEntryPoint = new BehaviorSubject<PurchaseEntryPoint>(PurchaseEntryPoint.ByMovie);

    getSelectedTicket(ticket: any) {
        this.ticketSource.next(ticket);
    }

    getTicketInfo(data: any) {
        this.ticketData.next(data);
    }

    getMappingInfo(info: any) {
        this.mappingSource.next(info);
    }

    getRefreshProfileFlag() {
        return this.refreshProfileFlag.asObservable();
    }

    setRefreshProfile() {
        this.refreshProfileFlag.next(true);
    }

    getRefreshRewardsCountFlag() {
        return this.refreshMyRewardsCountFlag.asObservable();
    }

    setRefreshRewardsCount() {
        this.refreshMyRewardsCountFlag.next(true);
    }

    getProfileQRToken() {
        return this.profileQR.asObservable();
    }

    setProfileQRToken(qr: AscentisQRToken) {
        this.profileQR.next(qr);
    }

    setMovieTicketType(type: MovieTicketType) {
        this.ticketType.next(type);
    }

    getMovieTicketType() {
        return this.ticketType.asObservable();
    }

    // setUpsellingType(type: UpsellingType) {
    //     return this.upsellingType.next(type);
    // }

    // getUpsellingType() {
    //     return this.upsellingType.asObservable();
    // }

    toHoursAndMinutes(duration: string): string {
        const totalMinutes = parseInt(duration, 10);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return `${hours} h ${minutes} m`;
    }

    groupBy<T>(arr: T[], keys: (keyof T)[]): { [key: string]: T[] } {
        return arr.reduce((storage, item) => {
            const objKey = keys.map((key) => `${item[key]}`).join(':');
            if (storage[objKey]) {
                storage[objKey].push(item);
            } else {
                // eslint-disable-next-line no-param-reassign
                storage[objKey] = [item];
            }
            return storage;
        }, {} as { [key: string]: T[] });
    }

    getDayOfWeekFromDate(date: string, dateFormat: string): string {
        return moment(date, dateFormat).format('ddd');
    }

    getMonthFromDate(date: string, dateFormat: string): string {
        return moment(date, dateFormat).format('MMM');
    }

    getDayFromDate(date: string, dateFormat: string): string {
        return moment(date, dateFormat).format('DD');
    }

    handleImageOnError(event: Event): void {
        // eslint-disable-next-line no-param-reassign
        (event.target as HTMLImageElement).src = '/assets/img/icons/Default_placeholder_web_img@2x.png';
    }

    getUnixTimestamp(date: string): number {
        if (!date) { return NaN; }

        const timestamps = date.match(/\d+/g);

        if (timestamps && timestamps.length) {
            return parseInt(timestamps[0], 10);
        }

        return NaN;
    }

    convertUnixToDate(date: string): string {
        const epoch = this.getUnixTimestamp(date);
        if (Number.isNaN(epoch)) {
            return '';
        }

        return moment(epoch).format();
    }

    convertDOBToDate(date: string): string {
        const epoch = date;
        if (Number.isNaN(epoch)) {
            return '';
        }

        return moment(epoch).format();
    }

    convertDateToUnix(date: string, dateFormat: string): number {
        return moment.utc(date, dateFormat).valueOf();
    }

    convertDOBToUnix(date: string, dateFormat: string, utcString: string): number {
        return moment.utc(date, dateFormat).utcOffset(utcString).valueOf();
    }

    msToMinsAndSecs(milliseconds: number): string {
        const mins = Math.floor((milliseconds / 60000));
        const secs = Math.floor((milliseconds % 60000) / 1000);

        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    }

    whitespaceValidator(form: FormControl): ValidationErrors | null {
        return form.value.startsWith(' ') ? { whitespace: true } : null;
    }

    inputWhitespaceValidator(control: any) {
        return (control.value || '').trim().length ? null : { 'whitespace': true };       
    }

    isNumeric(value: string) {
        return /^-?\d+$/.test(value);
    }

    getMyRewardsType(type: boolean) {
        this.myRewardsSource.next(type);
    }

    getBeforeLoginRoute(route: string) {
        this.getRouteSource.next(route);
    }

    setPurchaseEntryPoint(entryPoint: PurchaseEntryPoint) {
        this.purchaseEntryPoint.next(entryPoint);
    }

    getPurchaseEntryPoint() {
        return this.purchaseEntryPoint.asObservable();
    }

    formatString(val: string) {
        return val.replace(/\D/g, '');
    }

    getShowtimeInUnix(date: string, time: string): number {
        return moment(
            `${date} ${time}`,
            `${SHOWTIME_DATE_FORMAT} ${SHOWTIME_TIME_FORMAT_24_HOURS}`,
        ).valueOf();
    }

    onHash(data: string) {
        let hashData = data + SALT;
        return sha256(hashData).toUpperCase();
    }

    getSeatNames(selectedSeat: any[]) {
        const selectedSeats: any[] = [];
        let seatNames = '';

        selectedSeat.map((e) => {
            const name = e.$.row + e.$.value;
            selectedSeats.push(name);
        });

        selectedSeats.sort((one, two) => (one > two ? 1 : -1));

        const len = (selectedSeats.length - 1);

        selectedSeats.forEach((value, index) => {
            seatNames += value;
            if (index !== len) {
                seatNames += ', ';
            }
        });

        return seatNames.length > 0 ? seatNames : '-';
    }
}
